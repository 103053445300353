import { useStaticQuery, graphql } from 'gatsby'

export const useGuarantees = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxModules(
        filter: { contentful_id: { eq: "2fv4EP6HW9DL1zEnFop83g" } }
      ) {
        data: edges {
          node {
            moduleTitle
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1500)
                }
              }
            }
            moduleMisc {
              description
              pre
              post
            }
            contentSlots {
              slotTitle
              textparagraphquote {
                textparagraphquote
              }
              markdown {
                raw
              }
              photos {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}

export default useGuarantees
